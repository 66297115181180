import request from "@/utils/request";

export async function queryAppPushConfig(tenantId) {
  const response = await request({
    method: "get",
    url: `/iotEssence/appPushConfig`,
    params: { tenantId }
  });
  if (response.code === 0) {
    return response.data;
  } else {
    return [];
  }
}

/**
 * @param record {accountId,tenantId,pushType,params}
 * @returns {Promise<any|null>}
 */
export async function putAppPushConfig(record) {
  const response = await request({
    method: "put",
    url: `/iotEssence/appPushConfig`,
    data: record
  });
  return response.code === 0 ? response.data : null;
}

export async function getAppPushConfig(pushType, tenantId) {
  const response = await request({
    method: "get",
    url: `/iotEssence/appPushConfig/${pushType}/tenants/${tenantId}`
  });
  if (response.code === 0) {
    return response.data; // {accountId,tenantId,pushType,clientSystem,client,params,active}
  } else {
    return null;
  }
}

export async function queryPirConfig(tenantId) {
  const response = await request({
    method: "get",
    url: `/iotEssence/pirConfig`,
    params: { tenantId }
  });
  if (response.code === 0) {
    return response.data;
  } else {
    return [];
  }
}

/**
 * @param record {accountId,tenantId,pushType,params}
 * @returns {Promise<any|null>}
 */
export async function putPirConfig(record) {
  const response = await request({
    method: "put",
    url: `/iotEssence/pirConfig`,
    data: record
  });
  return response.code === 0 ? response.data : null;
}

export async function getPirConfig(nodeId, tenantId) {
  const response = await request({
    method: "get",
    url: `/iotEssence/pirConfig/${nodeId}/tenants/${tenantId}`
  });
  if (response.code === 0) {
    return response.data; // {accountId,tenantId,nodeId,envId,params,active}
  } else {
    return null;
  }
}
