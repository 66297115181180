<template>
  <div>
    <div class="panel-box">
      <div class="title-main">Notification Cert</div>
      <div class="flex-row-start-between line-box">
        <div><!--占位--></div>
        <a-button @click="refreshAll">Refresh All</a-button>
      </div>
    </div>
    <div
      v-for="record in records"
      :key="record.pushType"
      class="panel-box"
      style="margin-top: 16px"
    >
      <div class="flex-row-start-center line-box">
        <span class="line-key">Channel Name:</span>
        <span class="line-value">
          <a-link :href="channelInfos[record.pushType].url" target="blank">{{
              channelInfos[record.pushType].name
            }}</a-link>
        </span>
      </div>
      <div class="flex-row-start-center line-box">
        <span class="line-key">Client System:</span>
        <span class="line-value">{{ record.clientSystem }}</span>
      </div>
      <div class="flex-row-start-center line-box">
        <span class="line-key">Region:</span>
        <span class="line-value">{{ record.region }}</span>
      </div>
      <template v-if="record.pushType === '301'">
        <div class="flex-row-start-center line-box">
          <span class="line-key">App ID:</span>
          <span class="line-value">
            <a-input v-model="record.params.appId" placeholder=""></a-input>
          </span>
        </div>
        <div class="flex-row-start-center line-box">
          <span class="line-key">Secret Key:</span>
          <span class="line-value">
            <a-input v-model="record.params.secretKey" placeholder=""></a-input>
          </span>
        </div>
      </template>
      <template v-if="record.pushType === '101'">
        <div class="flex-row-start-center line-box">
          <span class="line-key">Key:</span>
          <!--          <a-input-->
          <!--            class="line-value"-->
          <!--            v-model="record.params.key"-->
          <!--            placeholder=""-->
          <!--          ></a-input>-->
          <span class="line-value">
            <a-textarea v-model="record.params.key" placeholder=""></a-textarea>
          </span>
        </div>
      </template>
      <template v-if="record.pushType === '202'">
        <div class="flex-row-start-center line-box">
          <span class="line-key">Cert File:</span>
          <div class="line-value flex-row-start-between">
            <span style="margin-right: 10px;">{{ record.params.certFileName }}</span>
            <a-link @click="$refs.certFilePicker.click()">select file</a-link>
          </div>
          <input type="file" hidden @change="onSelectFile(record,$event)" ref="certFilePicker" />
        </div>
        <div class="flex-row-start-center line-box">
          <span class="line-key">Cert Password:</span>
          <span class="line-value">
            <a-input v-model="record.params.certPassword" placeholder=""></a-input>
          </span>
        </div>
      </template>
      <div class="flex-row-start-center line-box">
        <a-space>
          <a-button @click="refreshAppPushConfig(record)">Refresh</a-button>
          <a-button @click="saveAppPushConfig(record)">Save</a-button>
        </a-space>
      </div>
    </div>
  </div>
</template>

<script>
import { queryAppPushConfig, putAppPushConfig, getAppPushConfig } from "@/api/iot-essence";

export default {
  data() {
    return {
      accountId: "",
      tenantId: null,
      records: [],
      channelInfos: {
        301: {
          name: "Tencent TPNS",
          url: "https://cloud.tencent.com/product/tpns"
        },
        101: {
          name: "Google FCM",
          url: "https://firebase.google.cn/docs/cloud-messaging"
        },
        202: {
          name: "Apple APNS",
          url: "https://developer.apple.com/documentation/usernotifications"
        }
      }
    };
  },
  async created() {
    this.accountId = this.$route.params.accountId;
    this.tenantId = this.$route.params.tenantId;
    this.records = await queryAppPushConfig(this.tenantId);
    console.log("queryAppPushConfig:", this.records);
  },
  methods: {
    async refreshAll() {
      this.records = await queryAppPushConfig(this.tenantId);
      console.log("queryAppPushConfig:", this.records);
    },
    async refreshAppPushConfig(record) {
      const data = await getAppPushConfig(record.pushType, record.tenantId);
      if (data) {
        this.copyFields(data, record);
      }
    },
    async saveAppPushConfig(record) {
      const channelName = this.channelInfos[record.pushType].name;
      for (const key in record.params) {
        const value = (record.params[key] || "").trim();
        if (value === "") {
          this.$message.warning({
            content: `${channelName}'s ${key} must not empty!`
          });
          return;
        }
      }
      if (JSON.stringify(record.params).length > 4000) {
        this.$message.warning({
          content: `${channelName}'s parameter is too large!`
        });
        return;
      }
      console.log("saveAppPushConfig:", record);
      const data = await putAppPushConfig({
        ...record, accountId: this.accountId, tenantId: this.tenantId
      });
    },
    async onSelectFile(record, event) {
      const files = event.target.files;
      if (files.length === 0) return;
      const file = files[0];
      console.log("onSelectFile:", file);
      const dataUrl = await this.readFileToDataUrl(file);
      record.params.certContent = dataUrl.substr(dataUrl.indexOf(",") + 1);
      record.params.certFileName = file.name;
      console.log("certContent:", record.params.certContent);
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
